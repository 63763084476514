@mixin snackbar($color) {
    border-left: 5px solid $color !important;
    border-radius: 10px;

    button {
        color: $color !important;
    }
}

.backoffice-snackbar {
    &--warn {
        @include snackbar(var(--backoffice-warning));
    }

    &--error {
        @include snackbar(var(--backoffice-error));
    }

    &--success {
        @include snackbar(var(--backoffice-success));
    }

    &--info {
        @include snackbar(var(--backoffice-info));
    }
}

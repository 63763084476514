// TODO this file should be removed if tailwind is added
.grid {
    display: grid;
}
.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.col-span-5 {
    grid-column: span 5 / span 5;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.gap-4 {
    gap: 1rem;
}

.items-center {
    align-items: center;
}

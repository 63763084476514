.u-flex-c {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.backoffice-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.backoffice-overlay {
    height: 100vh;
    width: 100vw;
    background-color: var(--backoffice-overlay-color);
    z-index: 2;
    top: 0;
    left: 0;
    position: fixed;
}

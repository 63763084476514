@use "@angular/material" as mat;
@include mat.core();

:root {
    --backoffice-error: #fa1e13;
    --backoffice-info: #266eb2;
    --backoffice-warning: #fdba31;
    --backoffice-success: #00ca00;
    --backoffice-primary: #1d9682;
    --backoffice-white: white;
    --backoffice-grey: #cacaca;
    --backoffice-dark: #3b3b3b;
    --backoffice-hover-color: #0000000d;
    --backoffice-overlay-color: rgb(0, 0, 0, 0.2);
}

$backoffice-palette: (
    50: #e4f2f0,
    100: #bbe0da,
    200: #8ecbc1,
    300: #61b6a8,
    400: #3fa695,
    500: #1d9682,
    600: #1a8e7a,
    700: #15836f,
    800: #117965,
    900: #0a6852,
    A100: #9affe5,
    A200: #67ffd8,
    A400: #34ffca,
    A700: #1affc4,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$backoffice-app-fe-primary: mat.m2-define-palette($backoffice-palette);
$backoffice-app-fe-accent: mat.m2-define-palette($backoffice-palette, A200, A100, A400);
$backoffice-app-fe-warn: mat.m2-define-palette(mat.$m2-red-palette);
$backoffice-app-fe-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $backoffice-app-fe-primary,
            accent: $backoffice-app-fe-accent,
            warn: $backoffice-app-fe-warn,
        ),
    )
);

@include mat.all-component-themes($backoffice-app-fe-theme);

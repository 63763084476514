.backoffice-button {
    cursor: pointer;
    border: none;
    background-color: transparent;

    mat-icon {
        font-size: var(--backoffice-text-large);
        color: var(--backoffice-grey);
        background-color: transparent;
        border-radius: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        mat-icon {
            color: var(--backoffice-primary);
        }
    }

    &-warning:hover {
        mat-icon {
            color: var(--backoffice-warning);
        }
    }
    &-error:hover {
        mat-icon {
            color: var(--backoffice-error);
        }
    }
}

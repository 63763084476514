@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import "https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined";

@import "./app/theme.scss";
@import "./app/shared/styles/sidebar.scss";
@import "./app/shared/styles/text.scss";
@import "./app/shared/styles/space.scss";
@import "./app/shared/styles/containers.scss";
@import "./app/shared/styles/buttons.scss";
@import "./app/shared/styles/snackbar.scss";

@import "./app/shared/styles/tailwind.scss";
* {
    font-family: "Roboto", sans-serif;
    font-weight: var(--backoffice-weight-heavy);
}

html,
body {
    margin: 0;
    height: 100%;
}

:root {
    --backoffice-weight-lighter: 100;
    --backoffice-weight-light: 200;
    --backoffice-weight: 300;
    --backoffice-weight-heavy: 400;
    --backoffice-weight-heavier: 500;

    --backoffice-text-smaller: 12px;
    --backoffice-text-small: 14px;
    --backoffice-text: 20px;
    --backoffice-text-large: 26px;
    --backoffice-text-larger: 34px;
    --backoffice-text-xlarge: 44px;
}

.center-text {
    text-align: center;
}

h1 {
    font-weight: var(--backoffice-weight-heavier);
}

h2 {
    font-weight: var(--backoffice-weight-heavy);
}

h3 {
    font-weight: var(--backoffice-weight);
}

h4 {
    font-weight: var(--backoffice-weight-light);
}

h5 {
    font-weight: var(--backoffice-weight-lighter);
}
